.countdown-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

}

.countdown-timer-item {
    font-size: 3rem;
    font-weight: bold;
    white-space: nowrap;
}