/* StayTunes.scss */

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100dvh;
}

.stay-tuned-container {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
    padding: 10px;
}

.stay-tuned-container h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.stay-tuned-container p {
    font-size: 1.5rem;
}

.copyright {
    padding: 10px;
}